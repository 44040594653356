<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    page: {
        title: "Detail Pendaftaran sertifikasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Detail Pendaftaran sertifikasi",
            items: [
                {
                    text: "Pendaftaran",
                    href: "/",
                },
                {
                    text: "Detal Pendaftaran Permohonan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            //modal
            showModalSimpan: false,

            id: this.$route.params.id,
            pendaftaran_timeline: [],
            pendaftaran_skema: [],
            pendaftaran_asesi: [],
            next_status: [],
            status_sekarang: [],
            pendaftaran: [],
            pendaftaran_surat_balasan: [],
            dokumen_persyaratan_dasar: [],
            hak_akses: false,
            dokumen_relevan: [],
            loadingTable: false,
            loadingDokumen: false,
            showModalSuratPermohonan: false,
            surat_permohonan: null,
            showModalKonfigurasiSuratBalasan: false,
            dokumen_balasan: [
                {
                    nama_hal: "Portofolio asesi sesuai dengan persyaratan yang terdapat di dalam skema",
                },
                {
                    nama_hal: "Pengisian APL-01 dan APL-02",
                },
                {
                    nama_hal: "Pelaksanaan Verifikasi TUK",
                },
                {
                    nama_hal: "Pembiayaan Sertifikasi",
                },
            ],
            optionsDokumen: [],
            hal_surat_balasan: "Surat Balasan Permohonan Sertifikasi",
            showPreviewSuratBalasan: false,
            showLoadingPreviewSuratBalasan: false,
            nomor_surat_balasan: null,
            path_draft_surat_balasan: null,
            disclaimerSuratBalasan: false,
            showModalTerbitkanSuratBalasan: false,
            path_surat_balasan: null,
            qr_path: null,
            checklist: false,
            showModalPassword: false,
            username: localStorage.session_username,
            showModalPreviewForm: false,
            linkPreviewForm: null,
            nama_dokumen: null,
            disclaimerPengisianDokumen: false,
            dokumen_id: null,
            skema_id: null,
            id_pendaftaran_asesi: null,
            status_persetujuan_admin_lsp: false,
            tanggal_surat_balasan: new Date().toISOString().slice(0, 10),
            skema_sertifikasi: null,

            // Proses pengisian form
            showModalPreviewDokumen: false,
            form_dokumen_muk: null,
            pendaftaran_dokumen: null,
            asesi_id: null,
            backend_url: process.env.VUE_APP_BACKEND_URL,
            backend_url_relevan: process.env.VUE_APP_BACKEND_URL,

            // verifikasi dokumen
            status_dokumen_verifikasi: null,
            id_dokumen_asesi: null,
            index_dokumen: null,
            showModalStatusDokumen: false,
            showDokumenPengisian: false,
            catatan: null,
            status_dokumen_verifikasi_terima: false,
            status_pengisian_dokumen: null,

            //modal surat permintaan
            showModalSuratPermintaan: false,
            surat_dokumen_upload: null,

            //modal password
            showModalPasswordGenerate: false,

            //modal tolak perbaikan
            showModalTolakPerbaikiDokumen: false,
            catatan_perbaikan: null,

            isShowSimpanGenerate: false
        };
    },
    mounted() {
        this.getDokumenMUK();
        this.getDetailPendaftaran();
    },
    methods: {
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD");
        },
        getDokumenMUK() {
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-dokumen-muk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsDokumen = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDetailPendaftaran() {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/detail",
                params: {
                    pendaftaran_id: self.id,
                    role_id: localStorage.session_role_active_id
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.pendaftaran = response_data_fix.pendaftaran;
                        self.hak_akses = response_data_fix.hak_akses;
                        self.pendaftaran_skema = response_data_fix.pendaftaran_skema;
                        self.pendaftaran_asesi = response_data_fix.pendaftaran_asesi;
                        self.pendaftaran_surat_balasan = response_data_fix.pendaftaran_surat_balasan;
                        self.pendaftaran_timeline = response_data_fix.pendaftaran_timeline;
                        self.next_status = response_data_fix.next_status;
                        self.status_sekarang = response_data_fix.status;
                        self.surat_permohonan = response_data_fix.surat_permohonan;
                        self.surat_dokumen_upload = response_data_fix.surat_dokumen_upload;
                        self.status_persetujuan_admin_lsp = response_data_fix.status_persetujuan_admin_lsp;
                        self.skema_sertifikasi = self.pendaftaran.skema_nama + " - " + self.pendaftaran.metode_nama;
                        var currentDate = new Date();
                        self.nomor_surat_balasan = response_data_fix.urutan + "/SB/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();

                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        modalSuratPermohonan() {
            let self = this;
            self.showModalSuratPermohonan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratPermohonan = true;
            });
        },
        modalSuratPermintaan() {
            let self = this;
            self.showModalSuratPermintaan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratPermintaan = true;
            });
        },
        modalTolakPerbaikiDokumen() {
            let self = this;
            self.showModalTolakPerbaikiDokumen = false;
            self.showModalPreviewForm = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalTolakPerbaikiDokumen = true;
                self.showModalPreviewForm = false;
            });
        },
        submitNextStatus() {
            this.storeUbahStatusPendaftaran();
        },
        storeUbahStatusPendaftaran() {
            let self = this;
            self.showModalKonfigurasiSuratBalasan = false;
            self.showModalTerbitkanSuratBalasan = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/ubah-status",
                data: {
                    id: self.id,
                    password: self.password,
                    next_status: self.next_status?.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    if(response.data.status == false){
                        Swal.fire({
                            icon: "error",
                            title: "Gagal",
                            text: response.data.data,
                        });
                    }else{
                        console.log(response);
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman pendaftaran segera",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                self.showModalPassword = false;
                                self.$router.push({ name: "all-pendaftaran" });
                            }
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.axiosCatchError = error.response;
                });
        },
        modalKonfigurasiSuratBalasan() {
            let self = this;
            self.showModalKonfigurasiSuratBalasan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalKonfigurasiSuratBalasan = true;
            });
        },
        modalTerbitkanSuratBalasan() {
            let self = this;
            self.showModalTerbitkanSuratBalasan = false;
            self.terbitkanSuratBalasan();
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalTerbitkanSuratBalasan = true;
            });
        },
        addHal() {
            this.dokumen_balasan.push({
                nama_hal: null,
            });
        },
        removeHal(index) {
            this.dokumen_balasan.splice(index, 1);
        },
        previewSuratBalasan() {
            let self = this;
            self.showLoadingPreviewSuratBalasan = true;
            self.showPreviewSuratBalasan = true;
            if (self.hal_surat_balasan == null || self.nomor_surat_balasan == null || self.tanggal_surat_balasan == null || self.dokumen_balasan.length == 0) {
                Swal.fire({
                    icon: "error",
                    title: "Pastikan Kelengkapan Pengisian Terisi Untuk Melanjutkan",
                });
                self.showLoadingPreviewSuratBalasan = false;
                self.showPreviewSuratBalasan = false;
            } else {
                self.storeKonfigSuratBalasan();
            }
        },
        storeKonfigSuratBalasan() {
            let self = this;
            self.password = null;
            self.showLoadingPreviewSuratBalasan = true;

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-surat-balasan",
                data: {
                    pendaftaran_id: self.id,
                    tanggal_surat_balasan: self.tanggal_surat_balasan,
                    nomor_surat_balasan: self.nomor_surat_balasan,
                    dokumen_balasan: JSON.stringify(self.dokumen_balasan),
                    perihal: self.hal_surat_balasan,
                    keterangan: self.keterangan_surat_balasan,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var response_data = response.data;
                    if (response.data.meta.code == 200) {
                        self.path_draft_surat_balasan = response_data.data.path_draft_surat_balasan;
                        self.showLoadingPreviewSuratBalasan = false;
                        self.showPreviewSuratBalasan = true;
                    }
                })
                .catch(function (error) {
                    self.axiosCatchError = error.response.data.data.error;
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        terbitkanSuratBalasan() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.path_surat_balasan = null;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/generate-surat-balasan", {
                    params: {
                        id: self.id,
                        dokumen_surat_tugas: "validasi",
                        status_dokumen: "fix",
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.qr_path = res_fix.qr;
                    self.path_surat_balasan = res_fix.pdf;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        modalPassword() {
            let self = this;
            self.showModalPassword = false;
            self.showModalTerbitkanSuratBalasan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalTerbitkanSuratBalasan = false;
                self.showModalPassword = true;
            });
        },
        checkPassword() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: this.username,
                    password: this.password,
                    is_one_time: false,
                })
                .then((response) => {
                    var data_login = response.data;
                    // check status login
                    if (data_login.status_login == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Oopss",
                            text: data_login.message_login,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    } else {
                        this.showModalPassword = false;
                        this.submitNextStatus();
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Periksa Kembali Username & Password Anda!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Terjadi Kesalahan, Coba lagi nanti",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun tidak ditemukan, periksa kembali username & password anda",
                        });
                    }
                });
        },
        modalDokumenPendaftaran(nama_dokumen, path, id_pendaftaran_asesi, asesi_id, status_pengisian_dokumen) {
            let self = this;
            self.nama_dokumen = nama_dokumen;
            self.asesi_id = asesi_id;
            self.id_pendaftaran_asesi = id_pendaftaran_asesi;
            self.status_pengisian_dokumen = status_pengisian_dokumen;
            self.linkPreviewForm = process.env.VUE_APP_BACKEND_URL + "/" + path;
            self.showModalPreviewForm = true;
            self.showDokumenPengisian = false;
            self.getDokumenPersyaratanDasar();
            self.getDokumenRelevan();
        },
        storeDokumenPendaftaran(id_pendaftaran_asesi, nama_dokumen, status_dokumen) {
            let self = this;
            self.password = null;
            self.showDokumenPengisian = false;
            self.isShowSimpanGenerate = true;
            self.showModalPasswordGenerate = false;
            self.showModalPreviewForm = false;

            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/store-dokumen",
                data: {
                    id: self.id,
                    pendaftaran_asesi_id: id_pendaftaran_asesi,
                    nama_dokumen: nama_dokumen,
                    approved_by: "admin_lsp",
                    status_dokumen: status_dokumen,
                    password: self.password,
                    catatan_perbaikan: self.catatan_perbaikan
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    self.getDetailPendaftaran();
                    if (status_dokumen == "generate") {
                        self.linkPreviewForm = response_data_fix.path_dokumen_apl1;
                        self.showModalPreviewForm = true;
                        self.showDokumenPengisian = true;
                    } else {
                        self.showModalPreviewForm = false;
                    }
                    self.disclaimerPengisianDokumen = false;
                    self.showModalTolakPerbaikiDokumen = false;
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    self.axiosCatchError = error.response;
                });
        },
        pengisianDokumenMUK(dokumen_id) {
            let self = this;
            self.dokumen_id = dokumen_id;
            self.form_dokumen_muk = '<i class="fas fa-spinner fa-spin"></i> Loading...';
            self.showModalPreviewDokumen = true;
            self.showModalPreviewForm = false;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/preview-dokumen", {
                    params: {
                        pendaftaran_id: self.pendaftaran.id,
                        dokumen_id: dokumen_id,
                        aksi_dokumen: "pengisian_dokumen_apl",
                        asesi_id: self.asesi_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    // var res_fix = response.data.data;
                    self.form_dokumen_muk = response.data.html;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        closeModalPreviewDokumen() {
            (this.showModalPreviewForm = true), (this.showModalPreviewDokumen = false);
        },
        getDokumenPersyaratanDasar() {
            let self = this;
            self.loadingDokumen = true;
            self.isShowSimpanGenerate = false;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-dokumen-persyaratan-dasar", {
                    params: {
                        pendaftaran_id: self.id,
                        pendaftaran_asesi_id: self.id_pendaftaran_asesi,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.dokumen_persyaratan_dasar = res_fix.data;
                    self.status_dokumen_verifikasi_terima = res_fix.status_dokumen;
                    self.loadingDokumen = false;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getDokumenRelevan() {
            let self = this;
            self.loadingDokumen = true;
            self.isShowSimpanGenerate = false;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/get-dokumen-relevan", {
                    params: {
                        pendaftaran_id: self.id,
                        pendaftaran_asesi_id: self.id_pendaftaran_asesi,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.dokumen_relevan = res_fix.data;
                    self.loadingDokumen = false;
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        verifikasiDokumen(status_dokumen_verifikasi, id_item, index) {
            let self = this;
            self.status_dokumen_verifikasi = status_dokumen_verifikasi;
            self.id_dokumen_asesi = id_item;
            self.index_dokumen = index;
            if (status_dokumen_verifikasi == "Tidak Bersedia") {
                self.showModalStatusDokumen = true;
            } else {
                self.prosesVerifikasiDokumen();
            }
        },
        prosesVerifikasiDokumen() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            self.showModalStatusDokumen = false;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "pendaftaran/verifikasi-dokumen",
                    {
                        id_dokumen_asesi: self.id_dokumen_asesi,
                        status_dokumen_verifikasi: self.status_dokumen_verifikasi,
                        notes: self.catatan,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    self.getDokumenPersyaratanDasar();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        modalPasswordGenerate() {
            let self = this;
            self.showModalPasswordGenerate = false;
            self.showDokumenPengisian = false;
            self.showModalPreviewForm = false;
            self.isShowSimpanGenerate = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalPasswordGenerate = true;
                self.showDokumenPengisian = false;
            });
        },
        checkPasswordGenerate() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "auth/login", {
                    username: self.username,
                    password: self.password,
                    is_one_time: false,
                })
                .then((response) => {
                    var data_login = response.data;
                    // check status login
                    if (data_login.status_login == false) {
                        Swal.fire({
                            icon: "warning",
                            title: "Oopss",
                            text: data_login.message_login,
                            showCancelButton: false,
                            showConfirmButton: true,
                        });
                    } else {
                        self.storeDokumenPendaftaran(self.id_pendaftaran_asesi, self.nama_dokumen, "generate_konfirmasi");
                        self.showModalPasswordGenerate = false;
                        self.showModalPreviewForm = false;
                        // self.getDetailPendaftaran();
                    }
                })
                .catch((error) => {
                    var message = error.response.data.data.error;
                    self.showModalPreviewForm = true;
                    self.showModalPasswordGenerate = false;
                    if (message == "Session has been used") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun anda telah login di perangkat lain, silahkan logout terlebih dahulu!",
                        });
                    } else if (message == "Account cant find") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Periksa Kembali Username & Password Anda!",
                        });
                    } else if (message == "Something went wrong") {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Terjadi Kesalahan, Coba lagi nanti",
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Akun tidak ditemukan, periksa kembali username & password anda",
                        });
                    }
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-body">
                                <b-tabs content-class="p-3 text-muted">
                                    <b-tab active class="border-0">
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">Detail Permohonan</span>
                                        </template>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <table class="table table-responsive table-md table-bordered table-striped">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Nomor Surat Permohonan</td>
                                                                    <td>{{ pendaftaran.pendaftaran_nomor }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tanggal Surat Permohonan</td>
                                                                    <td>{{ pendaftaran.pendaftaran_tanggal }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Skema Sertifikasi</td>
                                                                    <td>{{ pendaftaran.skema_nama }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Perihal</td>
                                                                    <td>{{ pendaftaran.perihal }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Nama PIC</td>
                                                                    <td>{{ pendaftaran.pic_nama }}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Nama Departemen</td>
                                                                    <td>
                                                                        <span v-if="pendaftaran.departemen_nama">
                                                                            {{ pendaftaran.departemen_nama }}
                                                                        </span>
                                                                        <span v-else>Data Belum Tersedia</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Status Pendaftaran</td>
                                                                    <td>
                                                                        <span class="badge bg-info"> {{ pendaftaran.status_nama }} </span>
                                                                    </td>
                                                                </tr>
                                                                <!-- <tr>
                                                                    <td> Surat Permohonan Sertifikasi</td>
                                                                    <td>
                                                                        <button class="btn btn-sm btn-info"
                                                                            v-on:click="modalSuratPermohonan"><i class="fas fa-eye"></i> Lihat Surat
                                                                            Permohonan</button>
                                                                    </td>
                                                                </tr> -->
                                                                <tr>
                                                                    <td>Dokumen Upload Permohonan Sertifikasi</td>
                                                                    <td>
                                                                        <div v-if="pendaftaran.path_surat_permintaan">
                                                                            <button class="btn btn-sm btn-info" v-on:click="modalSuratPermintaan"><i class="fas fa-eye"></i> Lihat Dokumen Upload Permohonan Sertifikasi</button>
                                                                        </div>
                                                                        <div v-else>Data Belum Tersedia</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-lg-12 mt-4" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009' || status_sekarang.id == 'e2f82042-4654-11ee-8710-0242ac110009' || status_sekarang.id == '24ed3180-3c10-11ee-a5ac-0242ac110009'">
                                                        <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                                                            <thead class="bg-dark text-center text-white">
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Asesi</th>
                                                                    <th>Nomor Registrasi</th>
                                                                    <th>Usulan Tanggal Asesmen</th>
                                                                    <th style="width: 50%">Dokumen</th>
                                                                    <!-- <th>Aksi</th> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="loadingTable">
                                                                    <td colspan="5" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                                </tr>
                                                                <tr v-else-if="pendaftaran_asesi == ''">
                                                                    <td class="text-center" colspan="5">Data Tidak Tersedia</td>
                                                                </tr>
                                                                <tr v-else v-for="(row_data, key_data) in pendaftaran_asesi" :key="key_data">
                                                                    <td class="text-center">
                                                                        {{ key_data + 1 }}
                                                                    </td>
                                                                    <td>
                                                                        {{ row_data.asesi_nama }}
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="row_data?.no_registrasi">
                                                                            {{ row_data.no_registrasi }}
                                                                        </div>
                                                                        <div v-else>Data Belum Tersedia</div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ row_data.tgl_usulan_asesmen }}
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <button class="btn btn-secondary rounded-pill m-1 p-2" v-if="row_data.status_dokumen_apl_1 == 0">FR.APL 01</button>
                                                                        <button class="btn btn-warning rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 01', row_data.path_dokumen_apl_1, row_data.id, row_data.asesi_id, row_data.status_dokumen_apl_1)" v-else-if="row_data.status_dokumen_apl_1 == 1">FR.APL 01</button>
                                                                        <button class="btn btn-success rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 01', row_data.path_dokumen_apl_1, row_data.id, row_data.asesi_id, row_data.status_dokumen_apl_1)" v-else-if="row_data.status_dokumen_apl_1 == 2">FR.APL 01</button>
                                                                        <button class="btn bg-dark text-white rounded-pill m-1 p-2" v-else>FR.APL 01</button>

                                                                        <button class="btn btn-secondary rounded-pill m-1 p-2" v-if="row_data.status_dokumen_apl_2 == 0">FR.APL 02</button>
                                                                        <button class="btn btn-warning rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 02', row_data.path_dokumen_apl_2, row_data.id, row_data.asesi_id, row_data.status_dokumen_apl_2)" v-else-if="row_data.status_dokumen_apl_2 == 1 && row_data.is_apl_2_kompeten == true">FR.APL 02</button>
                                                                        <button class="btn btn-info rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 02', row_data.path_dokumen_apl_2, row_data.id, row_data.asesi_id, row_data.status_dokumen_apl_2)" v-else-if="row_data.status_dokumen_apl_2 == 1 && row_data.is_apl_2_kompeten == false">FR.APL 02</button>
                                                                        <button class="btn btn-success rounded-pill m-1 p-2" @click="modalDokumenPendaftaran('FR.APL 02', row_data.path_dokumen_apl_2, row_data.id, row_data.asesi_id, row_data.status_dokumen_apl_2)" v-else-if="row_data.status_dokumen_apl_2 == 2">FR.APL 02</button>
                                                                        <button class="btn bg-dark text-white rounded-pill m-1 p-2" v-else>FR.APL 02</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-lg-12 mt-4" v-else>
                                                        <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                                                            <thead class="bg-dark text-center text-white">
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Asesi</th>
                                                                    <th>Nomor Registrasi</th>
                                                                    <th>Usulan Tanggal Asesmen</th>
                                                                    <!-- <th>Aksi</th> -->
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-if="loadingTable">
                                                                    <td colspan="4" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                                                </tr>
                                                                <tr v-else-if="pendaftaran_asesi == ''">
                                                                    <td class="text-center" colspan="4">Data Tidak Tersedia</td>
                                                                </tr>
                                                                <tr v-else v-for="(row_data, key_data) in pendaftaran_asesi" :key="key_data">
                                                                    <td class="text-center">
                                                                        {{ key_data + 1 }}
                                                                    </td>
                                                                    <td>
                                                                        {{ row_data.asesi_nama }}
                                                                    </td>
                                                                    <td>
                                                                        <div v-if="row_data?.no_registrasi">
                                                                            {{ row_data.no_registrasi }}
                                                                        </div>
                                                                        <div v-else>Data Belum Tersedia</div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        {{ row_data.tgl_usulan_asesmen }}
                                                                    </td>
                                                                    <!-- <td class="text-center">
                                                                        <div class="d-grid gap-1">
                                                                            <router-link
                                                                                :to="{ name: 'detail-daftar_pendaftaran', params: { id: 2 } }"
                                                                                class="btn btn-sm btn-primary bg-primary"><i
                                                                                    class="fas fa-search"></i>
                                                                                Detail</router-link>
                                                                        </div>
                                                                    </td> -->
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div class="row mt-4" v-if="hak_akses">
                                                    <div class="col-md-12 col-md-6">
                                                        <div class="text-end">
                                                            <!-- MENUNGGU SURAT BALASAN -->
                                                            <b-button v-if="status_sekarang.id == '1cc9196a-3c10-11ee-a5ac-0242ac110009'" v-on:click="modalKonfigurasiSuratBalasan()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                            <!-- Menunggu Persetujuan Ketua LSP -->
                                                            <b-button v-else-if="status_sekarang.id == '219c9fd4-3c10-11ee-a5ac-0242ac110009'" v-on:click="modalTerbitkanSuratBalasan()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                            <!-- Verifikasi Dokumen Admin LSP -->
                                                            <div v-else-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                                                                <b-button v-if="status_persetujuan_admin_lsp" v-on:click="modalPassword()" type="button" variant="primary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                                <b-button v-else type="button" variant="secondary" class="m-1 btn-sm"><i class="fa fa-save"></i> Proses Submit</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="mt-4" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009' || status_sekarang.id == 'e2f82042-4654-11ee-8710-0242ac110009'">
                                                        <h4>Keterangan</h4>
                                                    </div>
                                                    <div class="col-lg-4" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009' || status_sekarang.id == 'e2f82042-4654-11ee-8710-0242ac110009'">
                                                        <table class="table">
                                                            <tr>
                                                                <td><button class="btn bg-danger btn-danger rounded-pill" style="width: 75px">Merah</button></td>
                                                                <td>Data belum dilengkapi oleh asesi atau tidak diterima</td>
                                                            </tr>
                                                            <tr>
                                                                <td><button class="btn bg-warning btn-warning rounded-pill" style="width: 75px">Kuning</button></td>
                                                                <td>Menunggu verifikasi admin LSP</td>
                                                            </tr>
                                                            <tr>
                                                                <td><button class="btn bg-success btn-success rounded-pill" style="width: 75px">Hijau</button></td>
                                                                <td>Data sudah disetujui admin LSP</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-modal v-model="showModalSuratPermohonan" title="Preview Surat Permohonan" title-class="text-black font-18" size="xl" hide-footer>
                                            <div class="row">
                                                <div class="card-body">
                                                    <iframe v-bind:src="surat_permohonan" width="100%" height="400"></iframe>
                                                </div>
                                            </div>
                                        </b-modal>
                                        <b-modal v-model="showModalSuratPermintaan" title="Preview Dokumen Uplaod Surat Permohonan" title-class="text-black font-18" size="xl" hide-footer>
                                            <div class="row">
                                                <div class="card-body">
                                                    <iframe v-bind:src="surat_dokumen_upload" width="100%" height="400"></iframe>
                                                </div>
                                            </div>
                                        </b-modal>
                                        <b-modal v-model="showModalKonfigurasiSuratBalasan" title="Konfigurasi Draft Surat Balasan" title-class="text-black font-18" body-class="p-3" size="xl" hide-footer>
                                            <div class="row mx-2">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                                <label class="col-lg-5 col-form-label col-form-label" id="" for="text">Tanggal Surat Balasan <span class="required-field"></span></label>
                                                                <div class="col">
                                                                    <input id="" class="form-control" type="date" for="text" v-model="tanggal_surat_balasan" />
                                                                </div>
                                                            </div>
                                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                                <label class="col-lg-5 col-form-label col-form-label" id="" for="text">Nomor Surat Balasan <span class="required-field"></span></label>
                                                                <div class="col">
                                                                    <input class="form-control" type="text" for="text" v-model="nomor_surat_balasan" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Perihal <span class="required-field"></span></label>
                                                                <div class="col">
                                                                    <input v-model="hal_surat_balasan" class="form-control" readonly type="text" for="text" />
                                                                </div>
                                                            </div>

                                                            <!-- <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Keterangan</label>
                                                                <div class="col">
                                                                    <textarea v-model="keterangan_surat_balasan" class="form-control" type="text" for="text"></textarea>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <label for="">Hal Yang Perlu Disiapkan</label>
                                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                                        <thead class="bg-dark text-center text-white">
                                                            <tr>
                                                                <th style="width: 5%">No</th>
                                                                <th>Hal</th>
                                                                <th style="width: 5%">
                                                                    <button class="btn btn-success btn-sm" @click="addHal()"><i class="fas fa-plus"></i></button>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="dokumen_balasan.length == 0">
                                                                <td colspan="3">Tidak ada data</td>
                                                            </tr>
                                                            <tr v-else v-for="(item, index) in dokumen_balasan" :key="index">
                                                                <td>{{ index + 1 }}</td>
                                                                <td>
                                                                    <input type="text" class="form-control" v-model="item.nama_hal" />
                                                                </td>
                                                                <td class="text-center">
                                                                    <button class="btn btn-danger btn-sm" @click="removeHal(index)"><i class="fas fa-times"></i></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="row">
                                                        <div class="col-md-12 mt-3 text-end">
                                                            <button class="btn btn-info btn-sm" @click="previewSuratBalasan()"><i class="fas fa-eye"></i> Preview Draft Surat Balasan</button>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div v-if="showLoadingPreviewSuratBalasan"><i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Balasan ...</div>
                                                        <div v-else-if="showPreviewSuratBalasan">
                                                            <h4>Preview Draft Surat Balasan</h4>
                                                            <div class="card-body">
                                                                <iframe v-bind:src="path_draft_surat_balasan" width="100%" height="400"></iframe>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="showLoadingPreviewSuratBalasan"></div>
                                                    <div v-else>
                                                        <div class="row mt-2" v-if="showPreviewSuratBalasan">
                                                            <div class="col-md-6">
                                                                <div class="form-group"><input type="checkbox" v-model="disclaimerSuratBalasan" /> Apakah ingin melanjutkan ke proses selanjutnya?</div>
                                                            </div>
                                                            <div class="col-md-6 text-end">
                                                                <div class="text-end">
                                                                    <b-button v-if="disclaimerSuratBalasan == true" type="button" class="btn-sm" variant="primary" @click="submitNextStatus()"><i class="fas fa-save"></i> Proses Submit</b-button>
                                                                    <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Proses Submit</b-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-modal>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="my-0">Timeline</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" style="overflow-x: scroll">
                                <ul class="timeline timeline-horizontal">
                                    <li class="timeline-item" v-for="(val, key) in pendaftaran_timeline" :key="key">
                                        <!--v-if-->
                                        <div class="timeline-badge success">
                                            <i class="fa fa-check" style="margin-top: 12px"></i>
                                        </div>
                                        <div class="timeline-panel">
                                            <div class="timeline-heading">
                                                <b class="timeline-title">{{ val.pejabat_nama }}</b>
                                                <p>
                                                    <small class="text-muted"><i class="fa fa-clock-o"></i> {{ fullDateTimeFormat(val.created_at) }}</small>
                                                </p>
                                            </div>
                                            <div class="badge bg-info">{{ val.status_nama }}</div>
                                            <br />
                                            <div class="timeline-body">
                                                <b>Catatan : </b><br />
                                                <p>{{ val.notes }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    <b-modal v-model="showModalTerbitkanSuratBalasan" title="Preview Surat Balasan" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Balasan</strong></td>
                                    <td>: {{ pendaftaran_surat_balasan.nomor_surat_balasan }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Balasan</strong></td>
                                    <td>: {{ fullDateTimeFormat(pendaftaran_surat_balasan.tanggal_surat_balasan) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="path_surat_balasan" width="100%" height="400"></iframe>
                </div>
                <div class="form-group"><input type="checkbox" v-model="checklist" /> Apakah Anda yakin ingin menerbitkan surat balasan tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalTerbitkanSuratBalasan = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="checklist" @click="modalPassword"><i class="fas fa-save"></i> Terbitkan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Terbitkan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="submitNextStatus">
                <div class="form-group">
                    <input type="password" required v-model="password" id="" class="form-control" />
                </div>
                <div class="form-group text-end">
                    <button type="submit" class="btn btn-primary mt-3"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewForm" :title="nama_dokumen" title-class="text-black font-18" size="xl" hide-footer>
        <div class="row">
            <div class="card-body">
                <div class="row" v-if="nama_dokumen == 'FR.APL 01'">
                    <div class="col-md-12">
                        <h4>Bukti Persyaratan Dasar</h4>
                        <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                            <thead class="bg-dark text-center text-white">
                                <tr>
                                    <th style="width: 5%">No</th>
                                    <th style="width: 60%">Nama Dokumen</th>
                                    <th>Preview Dokumen</th>
                                    <th style="width: 20%" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="loadingDokumen">
                                    <td colspan="4" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                </tr>
                                <tr v-else-if="dokumen_persyaratan_dasar == ''">
                                    <td class="text-center" colspan="5">Data Tidak Tersedia</td>
                                </tr>
                                <tr v-else v-for="(item, key_data) in dokumen_persyaratan_dasar" :key="key_data">
                                    <td class="text-center">
                                        {{ key_data + 1 }}
                                    </td>
                                    <td>
                                        {{ item.bukti_dokumen_nama }}
                                    </td>
                                    <td class="text-center">
                                        <a :href="backend_url + item.path_dokumen" target="_blank" class="btn btn-sm btn-info"><i class="fas fa-eye"></i> Lihat Dokumen</a>
                                    </td>
                                    <td class="text-center" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                                        <div v-if="item.status_dokumen">
                                            <div class="btn-group" v-if="item.status_dokumen == 'Terima'">
                                                <b-button v-on:click="verifikasiDokumen('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                <button class="btn btn-success btn-sm" type="button"><i class="fas fa-check"></i> Terima</button>
                                            </div>
                                            <div class="btn-group" v-else-if="item.status_dokumen == 'Tolak'">
                                                <b-button v-on:click="verifikasiDokumen('Roleback', item.id, index)" type="button" variant="warning" class="btn-sm"><i class="bx bx-loader"></i> Roleback</b-button>
                                                <button class="btn btn-danger btn-sm" type="button"><i class="fas fa-times"></i> Tolak</button>
                                            </div>
                                            <div class="btn-group" v-else></div>
                                            <div v-if="item.status_dokumen == 'Tolak' && item.notes">
                                                <br />
                                                Alasan Tolak: {{ item.notes }}
                                            </div>
                                        </div>
                                        <div v-else>
                                            <b-button v-on:click="verifikasiDokumen('Tolak', item.id, index)" type="button" variant="danger" class="btn-sm"><i class="fa fa-times"></i> Tolak</b-button>
                                            <b-button v-on:click="verifikasiDokumen('Terima', item.id, index)" type="button" variant="success" class="btn-sm"><i class="fa fa-check"></i> Terima</b-button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6 mt-3"></div>
                    <div class="col-md-6 mt-3 text-end" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                        <div v-if="status_dokumen_verifikasi_terima">
                            <b-button class="btn-sm" type="button" variant="primary" @click="storeDokumenPendaftaran(id_pendaftaran_asesi, nama_dokumen, 'generate')"><i class="fas fa-save"></i> Generate Dokumen APL 01</b-button>
                        </div>
                        <div v-else>
                            <b-button type="button" variant="warning" class="btn-sm" style="margin-right: 1px" @click="modalTolakPerbaikiDokumen(id_pendaftaran_asesi, nama_dokumen, 'perbaikan')"><i class="bx bx-x"></i> Tolak Perbaiki</b-button>
                        </div>
                    </div>
                    <div class="row mt-3 mb-3">
                        <iframe v-bind:src="linkPreviewForm" width="100%" height="400"></iframe>

                        <div class="col-md-6 mt-3" v-if="isShowSimpanGenerate"><input type="checkbox" v-model="disclaimerPengisianDokumen" /> Apakah ingin menyimpan form tersebut?</div>
                        <div class="col-md-6 mt-3 text-end" v-if="isShowSimpanGenerate">
                            <b-button v-if="disclaimerPengisianDokumen" type="button" class="btn-sm" variant="primary" @click="modalPasswordGenerate()"><i class="fas fa-save"></i> Simpan</b-button>
                            <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan</b-button>
                        </div>
                    </div>
                    <!-- <div class="row mt-3 mb-3" v-if="status_sekarang.id == 'e2f82042-4654-11ee-8710-0242ac110009'">
                        <iframe v-bind:src="linkPreviewForm" width="100%" height="400"></iframe>

                        <div class="col-md-6 mt-3" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'"><input type="checkbox" v-model="disclaimerPengisianDokumen" /> Apakah ingin menyimpan form tersebut?</div>
                        <div class="col-md-6 mt-3 text-end" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                            <b-button v-if="disclaimerPengisianDokumen" type="button" class="btn-sm" variant="primary" @click="modalPasswordGenerate()"><i class="fas fa-save"></i> Simpan</b-button>
                            <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan</b-button>
                        </div>
                    </div> -->
                </div>
                <div class="row" v-else>
                    <h4>Bukti Dokumen Relevan</h4>
                    <table class="table mb-0 table-bordered table-condensed table-hover mx-2">
                        <thead class="bg-dark text-center text-white">
                            <tr>
                                <th style="width: 5%">No</th>
                                <th style="width: 60%">Nama Dokumen</th>
                                <th>Preview Dokumen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="loadingDokumen">
                                <td colspan="4" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                            </tr>
                            <tr v-else-if="dokumen_relevan == ''">
                                <td class="text-center" colspan="5">Data Tidak Tersedia</td>
                            </tr>
                            <tr v-else v-for="(item, key_data) in dokumen_relevan" :key="key_data">
                                <td class="text-center">
                                    {{ key_data + 1 }}
                                </td>
                                <td>
                                    {{ item.bukti_dokumen_nama }}
                                </td>
                                <td class="text-center">
                                    <a :href="backend_url_relevan + item.path_dokumen" target="_blank" class="btn btn-sm btn-info"><i class="fas fa-eye"></i> Lihat Dokumen</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row mt-5">
                        <iframe v-bind:src="linkPreviewForm" width="100%" height="400"></iframe>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group"><input type="checkbox" v-model="disclaimerPengisianDokumen" /> Apakah ingin menyimpan form tersebut?</div>
                    </div>
                    <div class="col-md-6 text-end">
                        <b-button v-if="disclaimerPengisianDokumen" type="button" class="btn-sm" variant="primary" @click="showModalPasswordGenerate()"><i class="fas fa-save"></i> Simpan</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan</b-button>
                    </div> -->
                </div>
            </div>
            <!-- <div class="card-footer">
                <div class="row">
                    <div class="col-md-6" v-if="nama_dokumen == 'FR.APL 01'" >
                        <div class="form-group" v-if="status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                            <input type="checkbox" v-model="disclaimerPengisianDokumen"> Apakah ingin menyimpan form tersebut?
                        </div>
                    </div>
                    <div class="col-md-6" v-else>
                    </div>
                    <div class="col-md-6 text-end" v-if="nama_dokumen == 'FR.APL 01' && status_sekarang.id == 'b33f445c-4654-11ee-8710-0242ac110009'">
                        <b-button style="margin-right:5px" v-if="nama_dokumen == 'FR.APL 01'" type="button" class="btn-sm" variant="info" @click="pengisianDokumenMUK('b88c9b46-39dc-11ee-9a36-0242ac110009')"><i class="fas fa-save"></i> Lihat Dokumen Persyaratan Dasar</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="info" @click="pengisianDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009')"><i class="fas fa-save"></i> Lihat Dokumen Relevan</b-button>

                        <b-button v-if="status_dokumen_verifikasi_terima" type="button" class="btn-sm" variant="primary" @click="storeDokumenPendaftaran(id_pendaftaran_asesi, nama_dokumen)"><i class="fas fa-save"></i> Simpan</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Simpan</b-button>
                    </div>
                    <div class="col-md-6 text-end" v-else>
                        <b-button v-if="nama_dokumen == 'FR.APL 01'" type="button" class="btn-sm" variant="info" @click="pengisianDokumenMUK('b88c9b46-39dc-11ee-9a36-0242ac110009')"><i class="fas fa-save"></i> Lihat Dokumen Persyaratan Dasar</b-button>
                        <b-button v-else type="button" class="btn-sm" variant="info" @click="pengisianDokumenMUK('b88c9f10-39dc-11ee-9a36-0242ac110009')"><i class="fas fa-save"></i> Lihat Dokumen Relevan</b-button>
                    </div>
                </div>
            </div> -->
        </div>
    </b-modal>
    <b-modal v-model="showModalPreviewDokumen" title="Preview Dokumen" title-class="text-black font-18" size="lg" hide-footer>
        <div class="row">
            <div class="card-body">
                <form id="form-dokumen-pendaftaran">
                    <div class="form-group">
                        <div v-html="form_dokumen_muk"></div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6 text-end">
                        <b-button variant="secondary" v-on:click="closeModalPreviewDokumen"><i class="fas fa-times"></i> Close</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalStatusDokumen" title="Alasan Tidak Bersedia" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="prosesVerifikasiDokumen">
                <div class="form-group">
                    <!-- <label for="">Alasan Penolakan</label> -->
                    <textarea v-model="catatan" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
                <div class="form-group text-end">
                    <button class="btn btn-primary mt-3"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalPasswordGenerate" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="storeDokumenPendaftaran(id_pendaftaran_asesi, nama_dokumen, 'generate_konfirmasi')">
                <div class="form-group">
                    <input type="password" required v-model="password" id="" class="form-control" />
                </div>
                <div class="form-group text-end">
                    <button type="submit" class="btn btn-primary mt-3"><i class="fas fa-save"></i> Proses</button>
                </div>
            </form>
        </div>
    </b-modal>
    <b-modal v-model="showModalTolakPerbaikiDokumen" title="Catatan Perbaikan" title-class="text-black font-18" hide-footer>
        <form id="form-tolak-perbaiki">
            <div class="form-group">
                <textarea class="form-control" v-model="catatan_perbaikan"></textarea>
            </div>
        </form>
        <div class="row mt-2">
            <div class="col-md-6"></div>
            <div class="col-md-6 text-end">
                <b-button variant="primary" v-on:click="storeDokumenPendaftaran(id_pendaftaran_asesi, nama_dokumen, 'perbaikan')"><i class="fas fa-save"></i> Simpan</b-button>
            </div>
        </div>
    </b-modal>
</template>
<style scoped>
.required-field::before {
    content: "*";
    color: red;
}
</style>
